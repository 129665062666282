<script setup lang="ts">
import { ref } from 'vue'
import VCurrencyField from '@/modules/shared/components/v-currency-field.vue'

const money = ref(null)
</script>

<template>
  <div class="mx-auto mt-40 w-1/2 bg-black p-5">
    <VCurrencyField v-model="money" />
  </div>
</template>
