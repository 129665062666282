<script setup lang="ts">
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { RouterLinkBack, VButtonInvisible } from '@/modules/shared/components'
import { useExtendedI18n } from '@/i18n'
import { capitalize } from 'lodash'
import CapitalCallForm from '../components/capital-call-form.vue'
import { useCapitalCallStore } from '../stores/capital-call-store'
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const { t } = useExtendedI18n()
const route = useRoute()
const router = useRouter()
const skeleton = ref(true)
const capitalCallStore = useCapitalCallStore()
const capital_call = ref(null)

const submit = async (payload) => {
  console.log('submit', payload)
  await capitalCallStore.updateCapitalCall(payload)
  router.push({ name: 'investing.calls' })
}

onMounted(async () => {
  await capitalCallStore.fetchCapitalCall(
    route.params.entity_type,
    route.params.entity_id,
    route.params.capital_call_id,
  )
  capital_call.value = capitalCallStore.items.get(parseInt(route.params.capital_call_id))
  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <div class="-mt-6 mb-6 flex items-center justify-between sm:-mt-8">
      <div>
        <RouterLinkBack :to="{ name: 'investing.calls' }">
          <VButtonInvisible>← {{ capitalize(t('shared.back')) }}</VButtonInvisible>
        </RouterLinkBack>
      </div>
    </div>
    <div class="mb-5 text-lg">Edit Capital Call</div>
    <CapitalCallForm :capital_call="capital_call" :onSave="submit" v-if="!skeleton" />
  </TheLayout>
</template>
