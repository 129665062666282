<script setup lang="ts">
import { computed, ref } from 'vue'
import { format } from 'date-fns'
import { useFocus } from '@vueuse/core'

const props = withDefaults(
  defineProps<{
    modelValue?: string | number | Date
    description?: string
    disabled?: boolean
    error?: string
    id?: string
    inline?: boolean
    inputClass?: string
    label?: string
    name?: string
    onChange?: any
    placeholder?: string
    property?: string
    required?: boolean
    type?: string
    v$?: any
  }>(),
  {
    disabled: false,
    inline: false,
    placeholder: '',
    type: 'text',
    v$: {},
    onChange: () => {},
  },
)

const emit = defineEmits(['update:modelValue'])
const inputRef = ref(null)
const { focused: isFocused } = useFocus(inputRef)

const value = computed({
  get: () => {
    if (props.type === 'date_without_timezone' && props.modelValue) {
      // remove date's timezone

      const date_in_timezone = new Date(props.modelValue)
      const date = new Date(date_in_timezone.getTime() + date_in_timezone.getTimezoneOffset() * 60 * 1000)
      return format(date, 'yyyy-MM-dd')
    }
    if (isFocused.value) {
      if (props.type === 'percent' && props.modelValue) {
        return props.modelValue.toString().replace(/[^0-9.-]+/g, '')
      }
    } else {
      if (props.type === 'percent' && props.modelValue) {
        return `${props.modelValue.toString().replace(/[^0-9.-]+/g, '')}%`
      }
    }
    return props.modelValue
  },
  set: (value) => emit('update:modelValue', value),
})

const error = computed(() => props.error || props.v$[props.property || props.name]?.$errors[0]?.$message)

const inputType = computed(() => {
  if (props.type === 'date_without_timezone') return 'date'
  if (props.type === 'percent') return 'text'
  return props.type
})
</script>

<template>
  <fieldset>
    <label :for="id" class="block text-sm font-medium text-gray-700">
      <span>{{ label }}</span>
      <span v-if="required">*</span>
    </label>
    <div :class="{ 'mt-1': !inline }">
      <input
        v-model="value"
        :class="[
          'block w-full',
          error
            ? 'border-red-300 focus:border-red-300 focus:ring-red-200'
            : 'border-gray-300 shadow-sm focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50',
          inline ? 'border-none' : 'mt-1 rounded-md border',
          disabled ? 'bg-gray-50 text-gray-500' : '',
          inputClass,
        ]"
        :disabled="disabled"
        :id="id"
        :name="name || property"
        :placeholder="placeholder"
        :type="inputType"
        @change="onChange"
        ref="inputRef"
      />
    </div>
    <template v-if="error && !inline">
      <p class="mt-1 text-sm text-red-500">{{ error }}</p>
    </template>
    <template v-if="description">
      <p class="mt-1 text-sm text-gray-500" :id="`${id}-description`">{{ description }}</p>
    </template>
  </fieldset>
</template>
