import { initialMoney, sum, minus, times, toNumber } from '@/modules/shared/utils/money'

type Transfer = {
  id: number | null
  capital: Money
  management_fee: Money
  other_fee: Money
  investor_set_commitment_id: number | null
  investor_set_transaction_id: number | null
  commitment: {
    id: number | null
    investor: {
      _cid: string
      id: number | null
      name: string | null
    }
    management_fee_percentage: number | null
    carried_interest_percentage: number | null
    commitment_remaining: Money
    ownership_percentage: number | null
  }
}
type TransformedTransfer = {
  id: number | null
  is_active: boolean
  name: string
  capital: Money
  management_fees: Money
  other_fees: Money
  total_called: Money
  commitment_remaining: Money
  pre_commitment_remaining: Money
  ownership_percentage: number | null
  fund_total_capital_committed: Money
  investor_set_commitment_id: number | null
}
type Macros = {
  total_capital: Money
  total_capital_percent: number
  management_fees: number
  other_fees: Money
}

const calculateTotalCalled = (transfer: TransformedTransfer): Money => {
  return sum([
    transfer.capital || initialMoney,
    transfer.management_fees || initialMoney,
    transfer.other_fees || initialMoney,
  ])
}
const calculateCommitmentRemaining = (transfer: TransformedTransfer): Money => {
  const commitment_remaining = minus(
    transfer.pre_commitment_remaining || initialMoney,
    transfer.total_called || initialMoney,
  )
  return commitment_remaining
}

export const transformTransfer = (transfer: Transfer, capital_call): TransformedTransfer => {
  const t = {
    id: transfer.id,
    is_active: true,
    name: transfer.commitment.investor.name,
    capital: transfer.capital,
    management_fees: transfer.management_fee,
    other_fees: transfer.other_fee,
    total_called: initialMoney,
    commitment_remaining: initialMoney,
    pre_commitment_remaining: transfer.commitment.commitment_remaining,
    management_fee_percentage: transfer.commitment.management_fee_percentage,
    ownership_percentage: transfer.commitment.ownership_percentage,
    fund_total_capital_committed: capital_call.fund_total_capital_committed,
    investor_set_commitment_id: transfer.investor_set_commitment_id,
  }

  t.total_called = calculateTotalCalled(t)
  t.commitment_remaining = calculateCommitmentRemaining(t)

  return t
}
export const updateTransfer = (transfer: TransformedTransfer) => {
  transfer.total_called = calculateTotalCalled(transfer)
  transfer.commitment_remaining = calculateCommitmentRemaining(transfer)
  return transfer
}
export const macrosUpdateTransfer = (
  transfer: TransformedTransfer,
  macros: Macros,
  selected_capital_type: string,
): TransformedTransfer => {
  const fund_total_capital_committed = transfer.fund_total_capital_committed
  const ownership_percentage = transfer.ownership_percentage
  const total_capital =
    selected_capital_type === '$'
      ? macros.total_capital || initialMoney
      : times(fund_total_capital_committed, (macros.total_capital_percent || 0) / 100)
  const management_fee_percent = !!macros.management_fees
    ? macros.management_fees / 100
    : transfer.management_fee_percentage

  const capital_before_fees = times(total_capital, ownership_percentage)
  transfer.management_fees = times(total_capital, management_fee_percent * ownership_percentage)
  transfer.capital = minus(capital_before_fees, transfer.management_fees)
  transfer.other_fees = times(macros.other_fees || initialMoney, ownership_percentage)

  transfer = updateTransfer(transfer)

  return transfer
}
