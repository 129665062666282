import { useStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { computed } from 'vue'
import { useFetch } from '@/modules/shared/composables/use-fetch'
import { rails_url } from '@/modules/shared/utils/rails'
import { useRoute } from 'vue-router'

///////////////////////////////////////////////////////////////////////////////
// Types
///////////////////////////////////////////////////////////////////////////////

type AccessToken = string

type Role = 'site_admin' | 'group_admin' | 'investor' | 'guest'

type Slug = string

type Status = 'active' | 'inactive'

///////////////////////////////////////////////////////////////////////////////
// Store
///////////////////////////////////////////////////////////////////////////////

type CurrentUser = {
  id: string | number
  email: string
  groups: any[]
  intercom_hash: string
  investor_id: string | number
  initials: string
  name: string
  profile_picture?: string
  role: Role
  status: Status
  two_factor_enabled_at?: string
  two_factor_requested_at: string
}

const initialState: CurrentUser = {
  id: null,
  email: null,
  groups: [],
  intercom_hash: null,
  investor_id: null,
  name: null,
  role: null,
  two_factor_requested_at: null,
}

export const useAuthStore = defineStore('authStore', () => {
  const route = useRoute()
  const access_token = useStorage<AccessToken>('access_token', null)
  const current_user = useStorage<CurrentUser>('current_user', { ...initialState })
  const original_user = useStorage<CurrentUser>('original_user', { ...initialState })
  const slug = useStorage<Slug>('slug', null)

  const has_current_user = computed(() => !!current_user.value.email)
  const is_authenticated = computed(() => !!access_token.value)
  const is_site_admin = computed(() => current_user.value.role === 'site_admin')
  const is_site_or_group_admin = computed(
    () => current_user.value.role === 'site_admin' || current_user.value.role === 'group_admin',
  )
  const logged_in_as = computed(
    () =>
      is_authenticated.value === true &&
      original_user?.value?.id &&
      current_user.value.investor_id != original_user.value.investor_id,
  )

  const logout = async () => {
    if (access_token.value) {
      await useFetch('/auth/logout').post().json()
    }

    reset()
  }

  const validateToken = async () => {
    const { error } = await useFetch('/auth/token/validate').get().json()

    if (error.value) {
      return false
    }

    return true
  }

  const reset = () => {
    access_token.value = null
    current_user.value = { ...initialState }
    original_user.value = { ...initialState }
    slug.value = null
  }

  const refresh = async (token?: AccessToken, route?: any) => {
    access_token.value = token || access_token.value
    const { data, error } = await useFetch(`/${route.params.slug}/auth/current`).get().json<{ data: CurrentUser }>()

    if (error.value) {
      console.error(error)
      current_user.value = { ...initialState }
      return
    }

    current_user.value = data.value.data
  }

  const requestVerificationCode = async (channel: 'sms' | 'email') => {
    const payload = { channel }
    const res = await useFetch('/auth/send-verification-code').post(payload).json<{ any }>()
    return res
  }

  const viewAs = async (user_id) => {
    if (!user_id) return
    original_user.value = current_user.value
    window.location.href = `${rails_url(route.params.slug)}/login-as/${user_id}`
  }

  return {
    access_token,
    current_user,
    logged_in_as,
    original_user,
    slug,

    has_current_user,
    is_authenticated,
    is_site_admin,
    is_site_or_group_admin,

    logout,
    refresh,
    requestVerificationCode,
    reset,
    validateToken,
    viewAs,
  }
})
