<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue'
import { onClickOutside, useConfirmDialog, useMagicKeys } from '@vueuse/core'
import { VIcon } from '../../shared/components'
import { search } from '@/modules/shared/utils/deep-search'
import { useSearch } from '@/modules/shared/composables/use-search'

type Option = {
  disabled?: boolean
  label: string
  value: string
}

const props = withDefaults(
  defineProps<{
    modelValue: string
    description?: string
    disabled: boolean
    error?: string
    id?: string
    inline?: boolean
    label?: string
    name?: string
    options: Option[]
    placeholder?: string
    property?: string
    required?: boolean
    v$?: any
    size?: string
  }>(),
  {
    disabled: false,
    inline: false,
    placeholder: '',
    size: 'md',
  },
)

const emit = defineEmits(['update:modelValue'])

///////////////////////////////////////////////////////////////////////////////
// Search
///////////////////////////////////////////////////////////////////////////////

const { clearQuery, query } = useSearch()
const preparedOptions = computed(() => search(props.options, query.value))

watch(
  () => props.modelValue,
  () => clearQuery(),
)

///////////////////////////////////////////////////////////////////////////////
// Modal
///////////////////////////////////////////////////////////////////////////////

const { isRevealed, reveal, cancel } = useConfirmDialog()
const { escape } = useMagicKeys()
const target = ref(null)
const search_bar = ref(null)

onClickOutside(target, (_event) => cancel())

const revealList = async () => {
  reveal()
  await nextTick()
  search_bar.value.focus()
}

watch(escape, (v) => {
  if (v) {
    cancel()
  }
})

///////////////////////////////////////////////////////////////////////////////
// Select
///////////////////////////////////////////////////////////////////////////////

const selectedValue = computed(() => props.modelValue)
const selectedOption = computed(() => props.options.find((option) => option.value === selectedValue.value))

const selectOption = (value: string) => {
  emit('update:modelValue', value)
  cancel()
}

const error = computed(() => props.error || props.v$[props.property || props.name]?.$errors[0]?.$message)

const selectClass = computed(() => {
  let classes = ['relative block w-full']
  switch (props.size) {
    case 'sm':
      classes.push('h-[38px] py-2 pl-3 pr-8 text-sm')
      break
    case 'md':
      classes.push('h-[42px] px-3 py-2')
      break
    case 'lg':
      classes.push('text-lg')
      break
  }
  if (error.value) {
    classes.push('border-red-300 focus:border-red-300 focus:ring-red-200')
  } else {
    classes.push('border-gray-300 shadow-sm focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50')
  }

  if (props.inline) {
    classes.push('border-none')
  } else {
    classes.push('mt-1 rounded-md border')
  }

  if (props.disabled) {
    classes.push('bg-gray-50 text-gray-500')
  } else {
    classes.push('cursor-pointer')
  }

  return classes
})
</script>

<template>
  <div>
    <label :for="id" class="block text-sm font-medium text-gray-700" v-if="label">
      <span>{{ label }}</span>
      <span v-if="required">*</span>
    </label>
    <div class="relative" ref="target" :class="{ 'z-[2]': isRevealed }">
      <div @click="isRevealed ? cancel() : revealList()" :class="selectClass">
        {{ selectedOption?.label || placeholder }}
        <VIcon name="chevron_down" class="absolute right-2 top-1/2 h-4 w-4 -translate-y-1/2" />
      </div>

      <ul
        v-show="!disabled && isRevealed"
        class="x-0 absolute mt-1 max-h-64 w-full divide-y-0 divide-gray-100 overflow-y-auto rounded-md bg-white shadow-lg ring-[1px] ring-gray-700/20"
      >
        <li>
          <div class="relative flex-grow">
            <input
              type="text"
              class="relative z-40 w-full rounded-none rounded-tl-md border-b border-l-0 border-r-0 border-t-0 border-gray-200 bg-transparent text-sm focus:border-gray-200 focus:ring focus:ring-sky-200 focus:ring-opacity-0"
              :placeholder="`Search by anything`"
              v-model="query"
              ref="search_bar"
            />
            <div class="pointer-events-none absolute inset-y-0 right-0 z-50 flex items-center pr-3">
              <button
                @click="clearQuery"
                class="curosr-pointer pointer-events-auto inline-block rounded-md bg-gray-100 p-1"
                v-show="query"
              >
                <VIcon name="x" class="h-3 w-3 text-gray-400" />
              </button>
            </div>
          </div>
        </li>
        <template v-for="option in preparedOptions" :key="option.value">
          <li
            v-if="option.disabled"
            class="flex items-center space-x-2 border-b bg-gray-50 px-3 py-1.5 pr-6 text-sm font-medium text-gray-400"
          >
            {{ option.label }}
          </li>
          <li
            v-else
            @click="selectOption(option.value)"
            class="flex cursor-pointer items-center space-x-2 border-b px-3 py-1.5 pr-6 text-sm font-medium text-gray-700 hover:bg-gray-100"
          >
            {{ option.label }}
          </li>
        </template>
      </ul>
      <template v-if="error && !inline">
        <p class="mt-2 text-sm text-red-500">{{ error }}</p>
      </template>
      <template v-if="description">
        <p class="mt-2 text-sm text-gray-500" :id="`${id}-description`">{{ description }}</p>
      </template>
    </div>
  </div>
</template>
